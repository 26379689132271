import React from 'react'
import { backgroundImage } from './Background'
import { Header } from './Header'
import { Sentence1 } from './Sentence1'

import './App.css'

function App() {
  const appClass = backgroundImage('#ffffff', 'rgba(236, 231, 218, 0.3)')
  return (
    <div className={`App ${appClass}`}>
      <Header />
      <Sentence1 />
    </div>
  )
}

export default App
