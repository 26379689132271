import React from 'react'
import rosaceMulticolor from './images/rosace-multicolor.svg'
import rosaceClair from './images/rosace-clair.svg'

export const Header = props => {
  return (
    <header className="App-header App-panel relative">
      <img src={rosaceClair} className="App-header__rosaceSide" style={{ left: 'calc(85vw)' }} alt="" />
      <img src={rosaceClair} className="App-header__rosaceSide" style={{ right: 'calc(85vw)' }} alt="" />
      <div className="App-title flex center column alignCenter">
        <div className="flex column center alignCenter">
          <div className="relative textCenter">
            <div className="App_title__sentenceContainer flex row wrap center">
              <div className="App-title__sentence App-title__sentence1">Célébration du </div>
              <div className="App-title__sentence App-title__sentence2">bicentenaire de la </div>
              <div className="App-title__sentence App-title__sentence3">naissance du</div>
            </div>
          </div>
          <div className="flex row noWrap center alignCenter">
            <span className="App-title__dates App-title__dates-1819">1819</span>
            <div>
              <img src={rosaceMulticolor} className="App-logo" alt="logo" />
            </div>
            <span className="App-title__dates App-title__dates-2019">2019</span>
          </div>
        </div>
        <div className="relative textCenter">
          <div className="App-title__bab">BÁB</div>
        </div>
      </div>
    </header>
  )
}
