import React from 'react'
import triangle from './images/triangle-dore.svg'
import etoile from './images/etoile.svg'

import './Sentence1.css'

export const Sentence1 = ({ innerRef, scrollPosition }) => {
  return (
    <div className="App-Sentence1" ref={innerRef}>
      <div className="flex column center alignCenter">
        <div className="flex center alignCenter">
          <img src={triangle} className="App-Sentence1-etoile__top" alt="rosace-top" />
        </div>
        <div className="App-Sentence1__sentence-text">
          <div className="App-Sentence1__sentence-text--firstLetter">L</div>e Báb est le précurseur de la foi bahá'íe.
          Au milieu du XIXe siècle, il a annoncé être le porteur d'un message destiné à transformer la vie spirituelle
          de l'humanité. Il avait pour mission de préparer la voie à l'avènement d'un second messager de Dieu, d’un rang
          plus élevé que lui, qui ouvrirait un âge de paix et de justice.
          <p className="App-Sentence1__author" style={{ fontSize: 'calc(15px + 2vmin)' }}>
            <a className="App-Sentence1__link" href="http://www.bahai.fr/la-foi-bahaie/figures-centrales-et-institutions/" target="_blank" rel="noreferrer noopener">
              www.bahai.fr
            </a>
          </p>
        </div>
        <div className="flex center alignCenter">
          <img src={etoile} className="App-Sentence1-etoile__mid" alt="etoie" />
        </div>

        <div className="App-Sentence1__sentence-text">
          <p>
            « Sa vie représente l’un des exemples les plus magnifiques de courage que le genre humain a pu avoir le
            privilège d’observer… »
          </p>
          <p className="App-Sentence1__author">A.L.M. Nicolas, Écrivain français du 19e siècle</p>
        </div>
        <div className="flex center alignCenter">
          <img src={triangle} className="App-Sentence1-etoile__bottom" alt="rosace-top" />
        </div>
      </div>
    </div>
  )
}
